<template>
  <div>
    <github-corner class="github-corner"/>
    <v-container class="container--fluid grid-list-md text-center">
      <v-card-text class="align-center">Dashboard</v-card-text>
      <v-row style="display: none">
        <v-col class="col-auto">
          <v-btn color="info" outlined @click="testEvent"> Test Event</v-btn>
        </v-col>
        <v-col class="col-auto">
          <v-btn color="info" outlined @click="testNotification"> Test Notification</v-btn>
        </v-col>
        <v-col class="col-auto">
          <v-btn color="info" outlined @click="getAllNotification"> Get Notification</v-btn>
        </v-col>
        <v-col class="col-auto">
          <v-btn color="info" outlined @click="getUnreadNotification"> Get Unread Notification</v-btn>
        </v-col>
        <v-col class="col-auto">
          <v-btn color="info" outlined @click="testOrderService()"> Test Order Service</v-btn>
        </v-col>
      </v-row>
      <v-card-text style="white-space: pre-line" class="align-content-start">{{ actionContent }}</v-card-text>
    </v-container>
  </div>
</template>

<script>
import GithubCorner from '@/admin/pages/Dashboard/GithubCorner.vue';
import OrderServices from "@/admin/services/OrderServices";
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Dashboard',
  components: {
    GithubCorner,
  },
  data() {
    return {
      actionContent: ''
    }
  },
  computed: {
    ...mapGetters({
      authenticatedUser: 'user/authenticatedUser',
    })
  },
  methods: {
    ...mapActions({
      getAllNotification: 'notification/all',
      getUnreadNotification: 'notification/getUnread',
      markNotificationAsRead: 'notification/markAsRead',
    }),
    testEvent() {
      this.actionContent += 'Start test event ...' + "\n";
      axios.get('user/sample-event', {params: {silent: true}}).then(() => {
        this.actionContent += 'Finished call to server to dispatch event' + "\n";
      })
    },
    testNotification() {
      this.actionContent += 'Start test notification ...' + "\n";
      axios.get('user/sample-notification', {params: {silent: true}}).then(() => {
        this.actionContent += 'Finished call to server to push notification' + "\n";
      })
    },
    async testOrderService() {
      console.log('test');
      let orderServices = new OrderServices();
      let order = await orderServices.getOrderById(1);
      console.log(order);
      orderServices.allOrder();
    }
  },
  mounted() {
    // if(!this.authenticatedUser.id_card || !this.authenticatedUser.phone || this.authenticatedUser.id_card.trim().length === 0 || this.authenticatedUser.phone.trim().length === 0) {
    //   this.noticeWarning('Phone, Identity Card required!');
    //   this.$router.push({name: 'Profile'})
    //   return;
    // }
    if (this.UserRole === this.CONSTANTS.ROLES.CUSTOMER) {
      this.$router.push({name: 'ManageOrders'})
    }
  }
};
</script>
